<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="手机号">
          <el-input></el-input>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input></el-input>
        </el-form-item>
        <el-form-item label="卡号">
          <el-input></el-input>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker v-model="date"></el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="status">
            <el-option :value="2" label="待审核"></el-option>
            <el-option :value="1" label="用户取消"></el-option>
            <el-option :value="3" label="已同意"></el-option>
            <el-option :value="4" label="已拒绝"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button type="success" @click="opendislog">充值</el-button>
        </el-form-item>
      </el-form>

      <el-table
        :data="pointList"
        :fit="true"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
        v-loading="is_loading"
      >
        <!-- <el-table-column label="交易单id" prop="id"></el-table-column> -->
        <el-table-column label="订单号" prop="flowing_no"></el-table-column>
        <el-table-column
          label="用户昵称"
          prop="map.userinfo.nick_name"
        ></el-table-column>
        <el-table-column label="用户卡号" prop="studycard_id"></el-table-column>
        <el-table-column
          label="用户手机号"
          prop="map.userinfo.mobile"
        ></el-table-column>
        <el-table-column
          label="充值权益点数"
          prop="course_num"
        ></el-table-column>
        <el-table-column label="付款金额" prop="amount"></el-table-column>
        <el-table-column label="付款方式" prop="pay_type"></el-table-column>
        <el-table-column
          label="交易创建时间"
          width="200"
          prop="rcharge_time"
        ></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <span
              v-if="scope.row.status == 0 && scope.row.finished == false"
              style="color: red"
              >已过期</span
            >
            <span v-else-if="scope.row.status == 2" style="color: green"
              >已付款</span
            >
            <span v-else-if="scope.row.status == 1" style="color: orange"
              >待付款</span
            >
            <span v-else-if="scope.row.status == 3" style="color: green"
              >退款审核中</span
            >
            <span v-else-if="scope.row.status == 4" style="color: green"
              >已退款</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 2"
              type="danger"
              size="small"
              @click="refoud(scope.row)"
              >退款
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="changeCurrentPage"
        :currentPage="currentPage"
        :total="payTotal"
      ></paging-fy>
    </el-row>
    <el-dialog :visible="refundReason" title="退款">
      <el-row style="display: flex" v-if="refundReason">
        <label>退款理由</label>
        <el-input
          type="textarea"
          style="width: 50%"
          v-model="reason"
        ></el-input>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="confirmRefund(temp)">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible="refuseReason || showReason"
      :title="refuseReason ? '拒绝退款' : showReason ? '拒绝理由' : ''"
    >
      <el-row style="display: flex" v-if="refuseReason">
        <label>拒绝理由</label>
        <el-input
          type="textarea"
          style="width: 50%"
          v-model="refuse_contect"
        ></el-input>
      </el-row>
      <el-row style="display: flex" v-if="showReason">
        <label>理由</label><span>{{ temp.refuse_contect }}</span>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="warning"
          v-if="refuseReason"
          @click="update(temp.id, 5)"
          >确 定</el-button
        >
        <el-button @click="handleClose" v-if="refuseReason">取 消</el-button>
        <el-button @click="showReason = false" v-if="showReason"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <!-- 充值 -->
    <el-dialog :visible="payShow" title="充值">
      <el-form label-width="80px" :model="Recharge">
        <el-form-item label="充值用户">
          <el-select
            v-model="r_user"
            filterable
            remote
            reserve-keyword
            placeholder="请输入用户电话"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in userList"
              :key="item.user_id"
              :label="item.nick_name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充值金额">
          <el-input
            v-model="Recharge.count"
            type="number"
            placeholder="请输入充值金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="Recharge.reason"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="付款凭证">
          <avatar-uploader
            @getImgUrl="Uploadpayimg"
            :clear="true"
            :url="Recharge.payimg"
          >
          </avatar-uploader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payShow = false">取 消</el-button>
        <el-button type="primary" @click="submitRecharge()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "财务管理中心", secondNav: "权益点充值记录" },
      mechanism_id: null,
      mechanism_list: [],
      date: null,
      pointList: [],
      status: null,
      is_loading: true,
      loading: false,
      refuseReason: false,
      refundReason: false,
      showReason: false,
      refuse_contect: "",
      currentPage: 1,
      payTotal: 0,
      reason: "",
      temp: {},
      //chongzhi
      userList: [],
      payShow: false,
      r_user: {},
      Recharge: {
        count: "",
        reason: "",
        payimg: "",
      },
    };
  },
  mounted() {
    this.getPointList();
  },
  methods: {
    //充值
    submitRecharge() {
      let url = "/user/rechargerecord/updateImage";
      let data = {
        mechanism_name: "",
        nick_name: "",
      };
    },
    remoteMethod(query) {
      if (query != "") {
        let url = "/user/user/queryByMessage";
        let data = {
          is_teach_paypal: true,
          login_name: query,
          register_platform: "wx",
        };
        this.$axios
          .get(url, {
            params: {
              ...data,
            },
          })
          .then((res) => {
            this.userList = res.data.data.rows;
          });
      }
    },
    getPointList() {
      this.is_loading = true;
      let url = "user/rechargerecord/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
            // start_time: this.date ? this.date.Format('yyyy-MM-dd') +' 00:00:00' : null,
            // update_time: this.date ? this.date.Format('yyyy-MM-dd')  + " 23:59:59": null,
            // status: 3,
            // nick_name: this.nick_name ? this.nick_name : null,
            rcharge_type: "point",
            is_teach_paypal: true,

            // id:this.id?this.id:null,
          },
        })
        .then((res) => {
          this.pointList = res.data.data.rows;
          this.payTotal = res.data.data.total;
          this.is_loading = false;
        });
    },
    opendislog() {
      this.payShow = true;
    },
    search() {
      this.currentPage = 1;
      this.getPointList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getPointList();
    },
    refuse(row) {
      this.temp = row;
      this.refuseReason = true;
    },
    showRefuse(row) {
      console.log(row);
      this.temp = row;
      this.showReason = true;
    },
    getMechanismList(query) {
      let url = "/user/mastermechanism/queryMechanismByType";
      this.$axios
        .get(url, {
          params: {
            // type: 'teach_paypal',
            categories: "体育运动",
            source: "体育宝",
            mechanism_name:
              typeof query == "string" && query != "" ? query : null,
            pageSize: 99,
            currentPage: 1,
          },
        })
        .then((res) => {
          // console.log(res);
          this.mechanism_list = res.data.data;
        });
    },
    update(id, status) {
      console.log(this.temp);
      let url = "/user/rechargerecordrefund/updateRefund";
      this.$axios
        .post(url, {
          id: id,
          status: status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.getPointList();
            this.refuseReason = false;
          } else {
            this.$message({ type: "warning", message: res.data.message });
          }
        });
    },
    refoud(row) {
      this.temp = row;
      this.refundReason = true;
    },
    confirmRefund() {
      let url = "/user/rechargerecordrefund/insert";
      if (!this.reason) {
        return this.$message({
          type: "error",
          message: "请输入退款原因!",
        });
      }
      this.$axios
        .post(url, {
          user_id: this.temp.user_id,
          mechanism_id: this.temp.mechanism_id,
          recharge_record_id: this.temp.id,
          reason: this.reason,
          type: "退款",
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "处理成功!等待审核结果!",
            });
            this.temp = {};
            this.reason = null;
            this.refundReason = false;
          }
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.refuseReason = false;
          this.showReason = false;
          this.refundReason = false;
          this.temp = {};
          done();
        })
        .catch((_) => {});
    },
    Uploadpayimg(file) {
      this.Recharge.payimg = file;
    },
  },
  watch: {
    mechanism_id(newV, oldV) {
      if (newV) {
        this.getPointList();
      }
    },
  },
};
</script>

<style></style>
